var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import logoText from './cupocheers_logo_text.svg';
import logoIced from './cupocheers_logo_iced.svg';
import logoHot from './cupocheers_logo_hot.svg';
import styles from './Logo.module.sass';
var Logo = function () {
    return (_jsxs(Box, __assign({ className: styles.logo }, { children: [_jsx("img", { src: logoText, className: styles.logoText, alt: "Cup O'Cheers logo" }), _jsx("img", { src: logoIced, className: styles.logoIced, alt: "Cup O'Cheers iced coffee logo image" }), _jsx("img", { src: logoHot, className: styles.logoHot, alt: "Cup O'Cheers hot coffee logo image" })] })));
};
export default Logo;
