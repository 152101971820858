var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
var imageList = [
    {
        title: 'Pink cozie with white frills',
        listUrl: 'https://cupocheers.s3.us-west-2.amazonaws.com/CupOCheers_pink_white_frills_medium.jpeg',
        detailUrl: 'https://cupocheers.s3.us-west-2.amazonaws.com/CupOCheers_pink_white_frills.jpeg',
    },
    {
        title: 'Red yarn cozie',
        listUrl: 'https://cupocheers.s3.us-west-2.amazonaws.com/CupOCheers_red_yarn_medium.jpeg',
        detailUrl: 'https://cupocheers.s3.us-west-2.amazonaws.com/CupOCheers_red_yarn.jpeg',
    },
    {
        title: 'Aqua cozie',
        listUrl: 'https://cupocheers.s3.us-west-2.amazonaws.com/CupOCheers_aqua.jpeg',
    },
    {
        title: 'Blue cozie with gold frills',
        listUrl: 'https://cupocheers.s3.us-west-2.amazonaws.com/CupOCheers_blue_gold_frills.jpeg',
    },
    {
        title: 'Brown cozie',
        listUrl: 'https://cupocheers.s3.us-west-2.amazonaws.com/CupOCheers_brown.jpeg',
    },
    {
        title: 'Christmas red cozie with gold flecks',
        listUrl: 'https://cupocheers.s3.us-west-2.amazonaws.com/CupOCheers_christmas_red.jpeg',
    },
    {
        title: 'Light orange cozie',
        listUrl: 'https://cupocheers.s3.us-west-2.amazonaws.com/CupOCheers_light_orange.jpeg',
    },
    {
        title: 'Light violet cozie',
        listUrl: 'https://cupocheers.s3.us-west-2.amazonaws.com/CupOCheers_light_violet.jpeg',
    },
    {
        title: 'White with pink frills',
        listUrl: 'https://cupocheers.s3.us-west-2.amazonaws.com/CupOCheers_white_pink_frills_medium.jpeg',
    },
];
var Gallery = function () {
    return (_jsx(ImageList, __assign({ cols: 3, gap: 8 }, { children: imageList.map(function (item) { return (_jsx(ImageListItem, { children: _jsx("img", { src: item.listUrl, alt: item.title, loading: "lazy" }) }, item.listUrl)); }) })));
};
export default Gallery;
